import React from "react";

import DylanRederthIMG from "../../content/img/flag.png"

const headsOfState = [
  {
    name: "Lord Count Greencastle",
    title: "Count/Lord Minister",
    position: "Lord Minister of Interior",
    email: "countgreencastle@rederth.org",
    image:
      "https://media.discordapp.net/attachments/1176741438759911494/1178110817238011924/Myers.png?ex=65f62733&is=65e3b233&hm=31a97670cf030fb8ba52046982afbecda74040dacd461a4bfeee970064509c97&=&format=webp&quality=lossless&width=617&height=617",
  },
  {
    name: "James Worrall",
    position: "Master of Arms",
    email: "jamesworrall@rederth.org",
    image:
      "https://media.discordapp.net/attachments/1168061562666356786/1210662383224234054/cztrsa.jpg?ex=65f49ab5&is=65e225b5&hm=194718ff1fb5853313fef06097fef46d597492d79bd467f6c1c2f05e06192596&=&format=webp&width=617&height=617",
  }
  // Add more heads of state as needed
];

const Heads = () => {
  return (
    <div className="container mt-4 mx-auto text-center">
      <h2 className="text-center mb-4" style={{ fontFamily: "lobster", color: "white" }}>
        Heads of the Grand Duchy
      </h2>

      {/*GRAND DUKE*/}
      <div className="row mx-auto">
        {/* First Card on Top */}
        <div className="col-md-3 mx-auto">
          {headsOfState.slice(0, 1).map((head, index) => (
            <div
              key={index}
              className="card text-white mb-3"
              style={{
                background: "#3f3f3f",
                border: "none",
                borderRadius: "10px",
                padding: "15px",
              }}
            >
              <img
                src={
                  "https://media.discordapp.net/attachments/1176741438759911494/1178111033089478686/Picsart_23-11-22_01-31-47-790.png?ex=65f62766&is=65e3b266&hm=60bc02c6f0633429146dbaf83a4c1e3dc630e2ab54194882c9337e16e474f6e6&=&format=webp&quality=lossless&width=631&height=617"
                }
                alt={"Photograph of Grand Duke Dylan I of Rederth"}
                className="card-img-top rounded mt-4"
                style={{ width: "200px", height: "200px", margin: "auto" }}
              />
              <div className="card-body">
                <h5 className="card-title" style={{ color: "#f8f9fa" }}>
                  Grand Duke {"  "} Dylan Rederth
                </h5>
                <p className="card-text" style={{ color: "#c0c0c0" }}>
                  <strong>Sovereign</strong>
                  <br />
                  Grand Duke of Rederth
                </p>
                <button
                  className="btn btn-light"
                  data-toggle="modal"
                  data-target={`#contactModalOne`}
                >
                  View Contact Info
                </button>
                {/* Modal for Contact Info */}
                <div
                  className="modal fade"
                  id={`contactModalOne`}
                  tabIndex="-1"
                  role="dialog"
                  aria-labelledby={`contactModalLabelOne`}
                  aria-hidden="true"
                >
                  <div className="modal-dialog" role="document">
                    <div
                      className="modal-content text-dark"
                      style={{ background: "#fff", color: "#f8f9fa" }}
                    >
                      <div className="modal-header">
                        <h5
                          className="modal-title"
                          id={`contactModalLabelOne`}
                        >
                          Dylan I's Contact Info
                        </h5>
                        <button
                          type="button"
                          className="close"
                          data-dismiss="modal"
                          aria-label="Close"
                        >
                          <span aria-hidden="true">&times;</span>
                        </button>
                      </div>
                      <div className="modal-body">
                        {/* Add contact information here */}
                        Email: <a href="mailto:dukerederth@rederth.org">dukerederth@rederth.org</a>
                      </div>
                      <div className="modal-footer">
                        <button
                          type="button"
                          className="btn btn-secondary"
                          data-dismiss="modal"
                        >
                          Close
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>

      {/* Row 2 */}
      <div className="row mx-auto">
        {/* Second Card on Top */}
        <div className="col-md-3 mx-auto">
          {headsOfState.slice(0, 2).map((head, index) => (
            <div key={index} className="card text-white mb-3" style={{ background: "#3f3f3f", border: "none", borderRadius: "10px", padding: "15px" }}>
              <img
                src={head.image}
                alt={head.name}
                className="card-img-top rounded mt-4"
                style={{ width: "200px", height: "200px", margin: "auto" }}
              />
              <div className="card-body">
                <h5 className="card-title" style={{ color: "#f8f9fa" }}>
                  {head.name}
                </h5>
                <p className="card-text" style={{ color: "#c0c0c0" }}>
                  <strong>{head.position}</strong>
                  <br />
                  {head.title}
                </p>
                <button
                  className="btn btn-light"
                  data-toggle="modal"
                  data-target={`#contactModal${index}`}
                >
                  View Contact Info
                </button>
                {/* Modal for Contact Info */}
                <div
                  className="modal fade"
                  id={`contactModal${index}`}
                  tabIndex="-1"
                  role="dialog"
                  aria-labelledby={`contactModalLabel${index}`}
                  aria-hidden="true"
                >
                  <div className="modal-dialog" role="document">
                    <div
                      className="modal-content text-dark"
                      style={{ background: "#fff", color: "#f8f9fa" }}
                    >
                      <div className="modal-header">
                        <h5
                          className="modal-title"
                          id={`contactModalLabel${index}`}
                        >
                          {head.name}'s Contact Info
                        </h5>
                        <button
                          type="button"
                          className="close"
                          data-dismiss="modal"
                          aria-label="Close"
                        >
                          <span aria-hidden="true">&times;</span>
                        </button>
                      </div>
                      <div className="modal-body">
                        Email: <a href={`mailto:${head.email}`}>{head.email}</a>
                      </div>
                      <div className="modal-footer">
                        <button
                          type="button"
                          className="btn btn-secondary"
                          data-dismiss="modal"
                        >
                          Close
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>

      {/* Row 3 */}
      <div className="row mx-auto">
        {/* Third Card on Top */}
        <div className="col-md-3 mx-auto">
          {headsOfState.slice(2, 3).map((head, index) => (
            <div key={index} className="card text-white mb-3" style={{ background: "#3f3f3f", border: "none", borderRadius: "10px", padding: "15px" }}>
              <img
                src={head.image}
                alt={head.name}
                className="card-img-top rounded mt-4"
                style={{ width: "200px", height: "200px", margin: "auto" }}
              />
              <div className="card-body">
                <h5 className="card-title" style={{ color: "#f8f9fa" }}>
                  {head.name}
                </h5>
                <p className="card-text" style={{ color: "#c0c0c0" }}>
                  <strong>{head.position}</strong>
                  <br />
                  {head.title}
                </p>
                <button
                  className="btn btn-light"
                  data-toggle="modal"
                  data-target={`#contactModal${index}`}
                >
                  View Contact Info
                </button>
                {/* Modal for Contact Info */}
                <div
                  className="modal fade"
                  id={`contactModal${index}`}
                  tabIndex="-1"
                  role="dialog"
                  aria-labelledby={`contactModalLabel${index}`}
                  aria-hidden="true"
                >
                  <div className="modal-dialog" role="document">
                    <div
                      className="modal-content text-dark"
                      style={{ background: "#fff", color: "#f8f9fa" }}
                    >
                      <div className="modal-header">
                        <h5
                          className="modal-title"
                          id={`contactModalLabel${index}`}
                        >
                          {head.name}'s Contact Info
                        </h5>
                        <button
                          type="button"
                          className="close"
                          data-dismiss="modal"
                          aria-label="Close"
                        >
                          <span aria-hidden="true">&times;</span>
                        </button>
                      </div>
                      <div className="modal-body">
                        Email: <a href={`mailto:${head.email}`}>{head.email}</a>
                      </div>
                      <div className="modal-footer">
                        <button
                          type="button"
                          className="btn btn-secondary"
                          data-dismiss="modal"
                        >
                          Close
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Heads;