import React from "react";

// Sample data for document entries
const documents = [
  {
    title: "Citizenship Requirements and Citizen Rights",
    description:
      "This document outlines the necessary requirements for applying for citizenship in the Grand Duchy of Rederth, along with the rights bestowed upon individuals granted citizenship in the Grand Duchy. It is crucial to comprehend this information thoroughly before initiating the citizenship application process.",
    link: "https://docs.google.com/document/d/1WXpmyDwinJfjSUb6vBaLcOmYuxWYfiBIStFtJ3mIKAk/edit?usp=sharing",
    pdfLink:
      "https://docs.google.com/document/d/1WXpmyDwinJfjSUb6vBaLcOmYuxWYfiBIStFtJ3mIKAk/export?format=pdf",
    docxLink: "https://docs.google.com/document/d/1WXpmyDwinJfjSUb6vBaLcOmYuxWYfiBIStFtJ3mIKAk/export?format=docx",
  },
  // {
  //   title: 'Document 2',
  //   description: 'This is the second document with a different description.',
  //   link: 'https://docs.google.com/document/d/your-document-id-2/edit',
  // },
  // Add more document entries as needed
];

const DocumentEntry = ({ title, description, link, pdfLink, docxLink }) => (
  <div
    className="card mb-3 text-white shadow-lg mx-auto"
    style={{ backgroundColor: "#808080", width: "70%" }}
  >
    <div
      className="card-body rounded"
      style={{ boxShadow: "inset 0 0 10px #000" }}
    >
      <h3 className="card-title" style={{ fontFamily: "lobster" }}>
        {title}
      </h3>
      <p className="card-text" style={{ fontFamily: "roboto" }}>
        {description}
      </p>
      <div class="btn-group">
        <button
          class="btn btn-danger"
          type="button"
          id="openDocumentBtn"
          style={{ fontFamily: "lobster" }}
          onClick={() => window.open(link, "_blank")}
        >
          Open Document
        </button>
        <div class="dropdown rounded-">
          <button
            class="btn btn-danger dropdown-toggle"
            type="button"
            id="downloadDropdown"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
            style={{ fontFamily: "lobster" }}
          ></button>
          <div class="dropdown-menu" aria-labelledby="downloadDropdown">
            <a
              class="dropdown-item"
              onClick={() => window.open(pdfLink, "_blank")}
              rel="noopener noreferrer"
              style={{cursor: 'pointer'}}
            >
              Download (PDF)
            </a>
            <a
              class="dropdown-item"
              onClick={() => window.open(docxLink, "_blank")}
              rel="noopener noreferrer"
              style={{cursor: 'pointer'}}
            >
              Download (DOCX)
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
);

const Docs = () => (
  <div
    className="container text-white rounded pt-1"
    style={{
      height: "100vh",
      backgroundColor: "#CC0100",
      boxShadow: "inset 0 0 10px #000",
    }}
  >
    <h1
      className="mt-4 mb-4 mx-auto rounded shadow-lg"
      style={{
        fontFamily: "lobster",
        width: "30%",
        backgroundColor: "#808080",
      }}
    >
      Document List
    </h1>
    {documents.map((doc, index) => (
      <DocumentEntry key={index} {...doc} />
    ))}
    <p style={{ fontStyle: "italic", fontFamily: "roboto" }}>
      More will be added as they are radicated.
    </p>
  </div>
);

export default Docs;
